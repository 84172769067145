import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    date: String,
    selected: {
      since: String,
      until: String
    }
  };
  static targets = ["day"];

  connect() {
    this.render();
  }

  dateValueChanged() {
    this.render();
  }

  selectedValueChanged() {
    this.render();
  }

  previousMonth() {
    this.updateMonth("previous");
  }

  nextMonth() {
    this.updateMonth("next");
  }

  render() {
    this.reset();
    const utcDate = new Date(this.dateValue);
    const localDate = new Date(utcDate.getTime());
    this.updateCalendar(localDate.getFullYear(), localDate.getMonth());
    this.highlightDaysInSelectedRange(this.selectedValue);
  }

  reset() {
    this.dayTargets.forEach(day => {
      day.classList.remove("active", "in-range", "range-start", "range-end");
      day.dataset.date = null;
      day.textContent = "";
    });
  }

  updateCalendar(year = new Date().getFullYear(), month = new Date().getMonth()) {
    const days = this.daysInMonth(year, month);
    let startingDay = new Date(days[0]).getDay();
    if (startingDay === 0) startingDay = 7;
    this.dayTargets.slice(startingDay - 1).forEach((day, index) => {
      if (index < days.length) {
        day.textContent = days[index].getDate();
        day.classList.add("active");

        let month = days[index].getMonth() + 1;
        if (String(month).length === 1) month = `0${month}`;

        let d = days[index].getDate();
        if (String(d).length === 1) d = `0${d}`;
        day.dataset.date = `${days[index].getFullYear()}-${month}-${d}`;
      }
    })
  }

  highlightDaysInSelectedRange(selectedRange) {
    this.dayTargets.forEach(day => {
      if (!day.dataset.date) return

      if (day.dataset.date == selectedRange.since) {
        day.classList.add("in-range", "range-start");
      }

      if (day.dataset.date == selectedRange.until) {
        day.classList.add("in-range", "range-end");
      }

      if (!selectedRange.since || !selectedRange.until) return

      if (new Date(day.dataset.date) > new Date(selectedRange.since) && new Date(day.dataset.date) < new Date(selectedRange.until)) {
        day.classList.add("in-range");
      }
    });
  }

  updateMonth(move) {
    const direction = move === "next" ? 1 : -1;
    const utcDate = new Date(this.dateValue);
    const localDate = new Date(utcDate.getTime());
    const newDate = new Date(localDate.getFullYear(), localDate.getMonth() + direction);

    this.dateValue = newDate.toISOString();
  }

  daysInMonth(year, month) {
    const numOfDays = new Date(year, month + 1, 0).getDate();
    const days = [];

    for (let i = 1; i <= numOfDays; i++) {
      days.push(new Date(year, month, i));
    }

    return days;
  }
}
