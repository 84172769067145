import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['month', 'since', 'sinceDisplay', 'until', 'untilDisplay', 'range']
  static values = {
    selectedRange: {
      since: String,
      until: String
    }
  }

  selectedRangeValueChanged() {
    this.sinceTarget.value = this.selectedRangeValue.since
    this.untilTarget.value = this.selectedRangeValue.until

    this.sinceDisplayTarget.value = this.displayDate(this.selectedRangeValue.since)
    this.untilDisplayTarget.value = this.displayDate(this.selectedRangeValue.until)

    let activeRange = 'Custom'
    for (const range of this.rangeTargets) {
      if (range.dataset.range === JSON.stringify(this.selectedRangeValue)) {
        activeRange = range.dataset.title
        break
      }
    }

    this.rangeTargets.forEach(range => {
      range.classList.toggle('selected', range.dataset.title === activeRange)
    })
  }

  previousMonth() {
    this.calendarControllers.forEach((calendar, index) => {
      calendar.previousMonth();
      this.updateDisplayedMonth(calendar, index);
    })
  }

  nextMonth() {
    this.calendarControllers.forEach((calendar, index) => {
      calendar.nextMonth();
      this.updateDisplayedMonth(calendar, index);
    })
  }

  updateDisplayedMonth(calendar, index) {
    const date = new Date(calendar.dateValue)
    const options = { year: "numeric", month: "long" }
    this.monthTargets[index].textContent = date.toLocaleString(undefined, options);
  }

  selectRange(event) {
    const range = JSON.parse(event.target.dataset.range);

    this.selectedRangeValue = range

    this.calendarControllers.forEach(calendar => {
      calendar.selectedValue = range
    })
  }

  select(event) {
    const date = event.target.dataset.date;
    if(isNaN((new Date(date)).getTime())) return

    let newRange = {...this.selectedRangeValue}

    if (newRange.until) {
      newRange = { since: date, until: null }
    } else {
      newRange = { since: newRange.since, until: date }
    }

    if (newRange.until && newRange.since && new Date(newRange.since) > new Date(newRange.until)) {
      newRange = { since: newRange.until, until: newRange.since }
    }

    this.selectedRangeValue = newRange

    this.calendarControllers.forEach(calendar => {
      calendar.selectedValue = newRange
    })
  }

  displayDate(date) {
    if (!date) return ''

    return new Date(date).toLocaleDateString(undefined, { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '-')
  }

  get calendarControllers() {
    return this.application.controllers.filter(c => c.identifier === 'date-picker--calendar--component')
  }
}
