// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

import AutoSubmit from '@stimulus-components/auto-submit'
import ScrollTo from 'stimulus-scroll-to'
import Reveal from 'stimulus-reveal-controller'
import ReadMore from 'stimulus-read-more'
import { registerPolarisControllers } from "polaris-view-components"

const application = Application.start()
application.register('auto-submit', AutoSubmit)
application.register('scroll-to', ScrollTo)
application.register('reveal', Reveal)
application.register('read-more', ReadMore)

registerPolarisControllers(application)

application.debug = false
window.Stimulus = application

const context = require.context("controllers/storefront", true, /_controller\.js$/)
const sharedContext = require.context("controllers/shared", true, /_controller\.js$/)
const componentsContext = require.context("../../../../components", true, /_controller\.js$/)

// for different reasons component's js controller names expected to be downcased
const componentDefinitions = definitionsFromContext(componentsContext).map(definition => {
  return { ...definition, identifier: definition.identifier.toLowerCase() }
})

const sharedDefinitions = definitionsFromContext(sharedContext)

window.Stimulus.load(definitionsFromContext(context).concat(componentDefinitions).concat(sharedDefinitions))

// console.log('Init done ...')
//
// document.addEventListener("turbo:load", event => {
//   console.log('Turbo load ...')
//   console.log(event)
// })
//
// document.addEventListener("turbo:click", event => {
//   console.log('Turbo click ...')
//   console.log(event)
// })
//
// document.addEventListener("turbo:submit-start", event => {
//   console.log('Turbo submit-start ...')
//   console.log(event)
// })